<template>
  <div id="onlineExam">
    <div class="hiddenDiv" :class="{ displayDiv: loaded }" v-if="!confirmed">
      <v-row>
        <v-col cols="12">
          <div class="title">
            <h1>{{ ExamData.class_name }}</h1>
          </div>
          <div class="title">
            <h2 class="main-color" v-if="ExamData.subject_name != ''">
              {{ ExamData.subject_name }} - {{ ExamData.exam_name }}
            </h2>
            <h2 class="main-color" v-else>
              {{ ExamData.exam_name }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="info-list">
          <ul class="inline">
            <li>
              <strong>{{ $t("Date") }}:</strong>
              {{ ExamData.exam_date }}
            </li>
            <li>
              <strong>{{ $t("Duration") }}:</strong>
              {{ ExamData.duration_hr }}:{{ ExamData.duration_min }}
            </li>
            <li v-if="ExamData.year_name != ''">
              <strong>{{ $t("Academic Period") }}:</strong>
              {{ ExamData.year_name }}
            </li>
            <li v-if="ExamData.qualifier_name != ''">
              <strong>{{ $t("Qualifier") }}:</strong>
              {{ ExamData.qualifier_name }}
            </li>
            <li>
              <strong>{{ $t("Marks") }}:</strong>
              {{ ExamData.total_mark }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="2" class="icons-div text-right">
          <v-icon class="main-color" @click="addSection()" large
            >add_circle</v-icon
          >
        </v-col>
        <v-col cols="12" md="12" class="hr-div">
          <hr />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-form v-model="valid" ref="form">
            <div
              class="exam-section"
              v-for="(section, sectionIndex) in examSections"
              :key="sectionIndex"
            >
              <v-row class="sectionInput">
                <v-col cols="11" md="11">
                  <label for="section head">{{ $t("Section") }}</label>
                  <v-textarea
                    solo
                    label
                    v-model="section.section_title"
                    :rules="requiredRules"
                    prepend-icon="drag_indicator"
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="1" md="1" style="margin-top: 25px">
                  <p>
                    <v-icon
                      class="main-color"
                      @click="duplicateSection(section)"
                      >file_copy</v-icon
                    >
                  </p>
                  <p>
                    <v-icon
                      class="main-color"
                      @click.prevent="
                        openConfirmDialog('section', index, sectionIndex)
                      "
                      >delete</v-icon
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-for="(question, index) in section.questions"
                :key="index"
              >
                <v-col cols="12" sm="12" class="questionbody">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <label>{{ $t("Question") }}</label>

                      <!-- <vue-editor
                        v-model="question.head"
                        :rules="requiredRules"
                      ></vue-editor> -->
                      <ckeditor
                        :editor="editor"
                        v-model="question.head"
                        :config="editorConfig"
                        :rules="requiredRules"
                      >
                      </ckeditor>
                      <p class="red--text" v-if="question.head == ''">
                        {{ $t("This field is required") }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>{{ $t("Question type") }}</label>

                      <v-select
                        :items="types"
                        v-model="question.type"
                        :item-name="types.text"
                        :item-value="types.value"
                        solo
                        :rules="[required.required]"
                        @change="
                          checkTypeQuestion(index, sectionIndex, question.type)
                        "
                      ></v-select>
                      <!-- @change="questionType(question.type, sectionIndex, index)" -->
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>{{ $t("Marks") }}</label>
                      <v-text-field 
                        v-model="question.Marks" type="number" min="0" 
                        :rules="[
                          required.required,
                          CheckIfMarksExceedExamMark(),
                          numeric.numeric,
                        ]" disabled solo v-if="question.type == 'join'" 
                           >
                      </v-text-field>
                      <v-text-field
                        v-model="question.Marks"
                        type="number"
                        min="0"
                        :rules="[
                          required.required,
                          CheckIfMarksExceedExamMark(),
                          numeric.numeric,
                        ]"
                        solo v-else
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="question.type == 'essay'"
                    >
                      <label>{{ $t("Min characters") }}</label>
                      <v-text-field
                        v-model="question.min"
                        min="0"
                        type="number"
                        solo
                        :rules="[
                          checkMinLessThanMax(question.min, question.max),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="question.type == 'essay'"
                    >
                      <label>{{ $t("Max characters") }} </label>
                      <v-text-field
                        v-model="question.max"
                        min="0"
                        type="number"
                        solo
                        :rules="[
                          checkMinLessThanMax(question.min, question.max),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="question.type == 'essay'">
                    <v-col cols="12" sm="12">
                      <!-- <v-textarea
                        solo
                        label
                        v-model="question.answer"
                        :placeholder="$i18n.t('Answer goes here')"
                        rows="2"
                      ></v-textarea> -->
                      <ckeditor
                        :editor="editor"
                        v-model="question.answer"
                        :config="editorConfig"
                        :rules="requiredRules"
                      ></ckeditor>
                      <!-- <label>Answer goes here</label> -->
                      <!-- <vue-editor v-model="question.answer"></vue-editor> -->
                    </v-col>
                  </v-row>
                  <!-- tof -->
                  <v-row v-if="question.type == 'tof'">
                    <v-col cols="12" sm="12">
                      <v-radio-group
                        v-model="question.answer"
                        :rules="requiredRules"
                        row
                      >
                        <v-radio
                          :label="$i18n.t('True')"
                          value="True"
                        ></v-radio>
                        <v-radio
                          :label="$i18n.t('False')"
                          value="False"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <!-- msq -->
                  <v-row v-if="question.type == 'mcq'">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      v-for="(option, optionIndex) in question.options"
                      :key="optionIndex"
                      class="active"
                    >
                      <v-row>
                        <v-col cols="10" sm="10">
                          <!-- <v-text-field
                            v-model="option.value"
                            :label="option.key"
                            solo
                            :rules="[
                              required.required,
                              checkIfOptionDuplicated(
                                question.options,
                                option.value
                              ),
                            ]"
                            >{{ option.value }}</v-text-field
                          > -->
                          <label>{{ option.key }} </label>
                          <!-- <vue-editor v-model="option.value"></vue-editor> -->
                          <ckeditor
                            :editor="editor"
                            v-model="option.value"
                            :config="editorConfig"
                            :rules="requiredRules"
                          ></ckeditor>
                          <p class="red--text" v-if="option.value == ''">
                            {{ $t("This field is required") }}
                          </p>
                        </v-col>
                        <v-col cols="1" sm="1" style="margin-top: 10px">
                          <v-icon
                            @click="
                              ChooseOption(index, sectionIndex, option.value)
                            "
                            :color="
                              option.value == question.answer &&
                              question.answer != ''
                                ? 'green'
                                : '#7297FF'
                            "
                            >check_circle</v-icon
                          >
                        </v-col>
                        <v-col cols="1" md="1" style="margin-top: 10px">
                          <v-icon
                            @click="
                              DeleteOption(index, sectionIndex, optionIndex)
                            "
                            class="main-color"
                            >delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- Join -->
                  <v-row v-if="question.type == 'join'">
                    <v-col
                      cols="12"
                      sm="12"
                      v-for="(join, joinIndex) in question.joins"
                      :key="joinIndex"
                      class="active"
                    >
                      <v-row class="align-center">
                        <v-col cols="6" sm="3">
                          <label>{{ join.join_question_number }}</label>
                          <v-text-field
                            v-model="join.join_question"
                            solo
                            :rules="[
                              validateJoinQuestion(
                                index,
                                sectionIndex,
                                joinIndex
                              ),
                            ]"
                            >{{ join.join_question }}</v-text-field
                          >
                          <!-- <vue-editor v-model="join.join_question">{{
                            join.join_question
                          }}</vue-editor> -->
                        </v-col>
                        <v-col cols="6" sm="2">
                          <label>{{ $t("Correct Answer") }}</label>
                          <v-select
                            :items="join.all_answers_items"
                            v-model="join.correct_answer"
                            solo
                            :rules="[
                              validateCorrectAnswer(
                                index,
                                sectionIndex,
                                joinIndex
                              ),
                            ]"
                            @change="
                              FilterCorrectAnswer(
                                index,
                                sectionIndex,
                                joinIndex
                              )
                            "
                          ></v-select>
                        </v-col>
                        <v-col cols="6" sm="4">
                          <label>{{ join.join_answer_number }}</label>
                          <v-text-field
                            v-model="join.join_answer"
                            solo
                            :rules="[required.required]"
                            >{{ join.join_answer }}
                          </v-text-field>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <label>Mark</label>
                          <v-text-field
                            v-model="join.join_mark"
                            min="0"
                            type="number"
                            solo
                            :rules="[
                              CheckIfJoinMarksExceedExamMark,
                              required.required,
                            ]"
                            @change="CollectQuestionMark(index, sectionIndex)"
                            @keyup="CollectQuestionMark(index, sectionIndex)"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="1" md="1" style="margin-top: 30px">
                          <v-icon
                            @click="
                              DeleteSentence(index, sectionIndex, joinIndex)
                            "
                            class="main-color"
                            >delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- mcq -->
                  <v-row v-if="question.type == 'mcq'">
                    <v-col cols="6" sm="6" class="addOption">
                      <span
                        class="clickable"
                        @click="AddOption(index, sectionIndex)"
                      >
                        <v-icon class="main-color" large>add</v-icon>
                        <span style="margin-top: 0.5rem">{{
                          $t("Add Option")
                        }}</span>
                      </span>
                    </v-col>
                  </v-row>
                  <!-- join -->
                  <v-row v-if="question.type == 'join'">
                    <v-col cols="6" sm="6" class="addOption">
                      <span class="clickable">
                        <v-icon
                          class="main-color"
                          large
                          @click="AddSentence(index, sectionIndex)"
                          >add</v-icon
                        >
                        <span
                          class="addSentenceSpan"
                          @click="AddSentence(index, sectionIndex)"
                          >{{ $t("Add Sentence") }}</span
                        >
                      </span>
                    </v-col>
                  </v-row>

                  <hr class="hr-bold" />
                  <v-row class="tags">
                    <v-col cols="12" sm="6" md="6">
                      <vue-tags-input
                        :placeholder="$t('Add Tag')"
                        v-model="tag"
                        :tags="question.tags"
                        :autocomplete-items="filteredItems"
                        @tags-changed="(newTags) => (question.tags = newTags)"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right">
                      <v-icon
                        class="main-color"
                        @click="duplicateQuestion(index, sectionIndex)"
                        >file_copy</v-icon
                      >

                      <v-icon
                        class="main-color"
                        @click="
                          openConfirmDialog('question', index, sectionIndex)
                        "
                        >delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-row class="divider"></v-row>
              </v-row>
              <v-row class="addQuestion">
                <span class="clickable" @click="addQuestion(sectionIndex)">
                  <v-icon class="main-color" large>add</v-icon>
                  <span style="margin-top: 0.5rem">{{
                    $t("Add question")
                  }}</span>
                </span>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" sm="12">
                <div class="btns text-right">
                  <v-btn
                    class="ui-btn submit"
                    v-if="!examSaved"
                    @click.prevent="saveExam"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <!-- @click.prevent="" -->
                    {{ $t("Save") }}
                  </v-btn>
                  <v-btn
                    class="ui-btn submit"
                    v-if="examSaved && confirmed == false"
                    @click.prevent="updateExam()"
                    :disabled="loading"
                    :loading="loading"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="300"
        class="dialog_confirm"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirmation Message")
          }}</v-card-title>

          <v-card-text>
            {{ $t("Are you sure you want to permanently remove these") }}
            {{ $t(deletedItem) }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="modal-btn-cancel noBtn2"
              @click="confirmDialog = false"
              >{{ $t("No") }}</v-btn
            >
            <v-btn
              @click="deleteItem(deletedItem)"
              class="modal-btn-save yesBtn2"
              >{{ $t("Yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="loader text-center" v-if="!loaded">
      <img src="../../assets/Spinner-1s-200px.gif" alt />
    </div>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <div v-if="confirmed">
      <v-row>
        <v-col cols="12" md="12">
          <v-alert class="info_alert">
            {{
              $t("You Cannot Edit This Exam Questions Because It's confirmed")
            }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import { VueEditor } from "vue2-editor";
import Editor from "../../../ckeditor-build/build/ckeditor";
import axios from "axios";
import VueTagsInput from "@johmun/vue-tags-input";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "createOnlineExam",
  components: {
    VueTagsInput,
    // VueEditor,
  },
  mixins: [validationMixin],
  data() {
    return {
      snack: false,
      loading: false,
      editor: Editor,
      editorConfig: {
        // The configuration of the editor.
      },
      snackText: "",
      snackColor: "",
      tab: null,
      academicYearID: null,
      snackTime: 3000,
      selectedType: "essay",
      classId: "",
      examId: "",
      admission: false,
      examSaved: false,
      confirmed: false,
      ExamData: {},
      loaded: false,
      valid: false,
      deletedItem: "",
      confirmDialog: false,
      selectedQuestionIndex: "",
      selectedSectionIndex: "",
      types: [
        { text: this.$i18n.t("Essay"), value: "essay" },
        { text: this.$i18n.t("JOIN The Sentences"), value: "join" },
        { text: this.$i18n.t("MCQ"), value: "mcq" },
        { text: this.$i18n.t("True or False"), value: "tof" },
      ],
      tag: "",
      autocompleteItems: [],
      examSections: [
        {
          section_title: "",
          questions: [
            {
              head: "",
              type: "essay",
              Marks: "",
              min: "",
              max: "",
              tags: [],
              tag: "",
              question: "",
              answer: "",
              options: [],
              joins: [],
            },
          ],
        },
      ],
      alphapaticChars: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      requiredRules: [(v) => !!v || this.$i18n.t("This field is required")],
      required: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
      },
      numeric: {
        numeric: (v) =>
          v >= 0 || this.$i18n.t("Number should be more or equal to 0"),
      },
    };
  },
  methods: {
    CheckIfJoinMarksExceedExamMark() {
      let total_mark = 0;
      let exam_mark = parseFloat(this.ExamData.total_mark);
      this.examSections.forEach((section) => {
        for (let i = 0; i < section.questions.length; i++) {
          if (section.questions[i].type != "join") {
            if (section.questions[i].Marks != "")
              total_mark += parseFloat(section.questions[i].Marks);
          } else {
            section.questions[i].joins.forEach((join) => {
              if (join.join_mark != "")
                total_mark += parseFloat(join.join_mark);
            });
          }
        }
      });
      // console.log(total_mark);
      if (total_mark > exam_mark) {
        return "Total questions marks shouldnot exceed exam mark";
      }
      return true;
    },
    getTags() {
      axios
        .get(this.getApiUrl + "/onlineexams/getTags", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error != true) {
            // console.log(response.data.data);
            this.autocompleteItems = response.data.data;
          }
        });
    },
    CheckJoinMarks(questionIndex, sectionIndex, type) {
      if (type == "join") {
        let joinMark = 0;
        this.examSections[sectionIndex]["questions"][
          questionIndex
        ].joins.forEach((join) => {
          if (join.join_mark != "") {
            joinMark += join.join_mark;
          }
        });
        if (
          joinMark >
          this.examSections[sectionIndex]["questions"][questionIndex]["Marks"]
        ) {
          return "Join total cannot be greater than question total";
        } else {
          return true;
        }
      }
      return true;
    },
    checkMinLessThanMax(min, max) {
      if (min && max) {
        if (parseFloat(min) > parseFloat(max)) {
          return "Min characters should be less than max characters";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    CheckIfMarksExceedExamMark() {
      let total_mark = 0;
      let exam_mark = parseFloat(this.ExamData.total_mark);
      this.examSections.forEach((section) => {
        section.questions.forEach((question) => {
          total_mark += parseFloat(question.Marks);
        });
      });
      if (total_mark > exam_mark) {
        return "Total questions marks shouldnot exceed exam mark";
      }
      return true;
    },
    validateCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      if (
        (this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]["join_question"] != "" ||
          joinIndex == 0) &&
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]["correct_answer"] == ""
      ) {
        return this.$i18n.t("This field is required");
      }
      return true;
    },
    validateJoinQuestion(questionIndex, sectionIndex, joinIndex) {
      if (
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]["join_question"] == "" &&
        joinIndex == 0
      ) {
        return this.$i18n.t("This field is required");
      }
      return true;
    },
    FilterCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      let answer =
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]["correct_answer"];
      let empty_all_item_answers;
      empty_all_item_answers = [
        ...this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]["all_answers_items"],
      ];
      let correct_answers_array = [];
      let question_numbers_array = [];
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_array.push(join.correct_answer);
        question_numbers_array.push(join.join_answer_number);
        if (join.correct_answer != answer && answer != "") {
          let all_answers_items = [];
          for (let index = 0; index < join.all_answers_items.length; index++) {
            if (join.all_answers_items[index] != answer) {
              all_answers_items.push(join.all_answers_items[index]);
            }
          }

          join.all_answers_items = [];
          join.all_answers_items = [...all_answers_items];
        } else if (answer == "") {
          empty_all_item_answers.forEach((item) => {
            let answer_found = join.all_answers_items.includes(item);
            // console.log(answer_found);
            if (!answer_found) {
              join.all_answers_items.push(item);
            }
          });
        }
      });
      let questions_numbers_not_in_correct_answers = [];
      question_numbers_array.forEach((number) => {
        if (!correct_answers_array.includes(number)) {
          questions_numbers_not_in_correct_answers.push(number);
        }
      });
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        questions_numbers_not_in_correct_answers.forEach((number) => {
          if (!join.all_answers_items.includes(number)) {
            join.all_answers_items.push(number);
          }
        });
      });
    },
    CollectQuestionMark(questionIndex, sectionIndex) {
      let mark = 0;
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        if (join.join_mark != "") mark += parseFloat(join.join_mark);
      });
      this.examSections[sectionIndex]["questions"][questionIndex]["Marks"] =
        mark;
    },
    
    checkIfOptionDuplicated(options, value) {
      let error = true;
      let exist = 0;
      options.forEach((option) => {
        if (option.value == value) {
          // console.log(value);
          exist += 1;
        }
      });
      if (exist > 1) {
        return "This answer already exist";
      }
      return error;
    },
    ChooseOption(questionIndex, sectionIndex, optionValue) {
      // console.log(optionValue);
      if (optionValue != "") {
        this.examSections[sectionIndex]["questions"][questionIndex]["answer"] =
          optionValue;
      }
    },
    DeleteSentence(questionIndex, sectionIndex, joinIndex) {
      if (
        "id" in
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          joinIndex
        ]
      ) {
        axios
          .get(
            this.getApiUrl +
              "/onlineexams/deleteOptionJoin/" +
              this.examSections[sectionIndex]["questions"][questionIndex][
                "joins"
              ][joinIndex]["id"],
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == true) {
              this.snack = true;
              this.snackText = "error in delete sentence";
              this.snackColor = "red";
            }
          });
      }

      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].splice(joinIndex, 1);
      for (
        let i = 1;
        i <=
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"]
          .length;
        i++
      ) {
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
          i - 1
        ]["join_question_number"] = i;
        if (i - 1 == 0) {
          this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
            i - 1
          ]["join_answer_number"] = "a";
        } else {
          this.examSections[sectionIndex]["questions"][questionIndex]["joins"][
            i - 1
          ]["join_answer_number"] =
            this.alphapaticChars[
              this.alphapaticChars.indexOf(
                this.examSections[sectionIndex]["questions"][questionIndex][
                  "joins"
                ][i - 2]["join_answer_number"]
              ) + 1
            ];
        }
      }
      let all_answers_items = [];
      all_answers_items.push("");
      // let correct_answers_items = [];
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.correct_answer = "";
        all_answers_items.push(join.join_answer_number);
      });
      // this.examSections[sectionIndex]["questions"][questionIndex][
      //   "joins"
      // ].forEach(join => {
      //   if (correct_answers_items.includes())
      //     all_answers_items.push(join.join_answer_number);
      // });
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.all_answers_items = [...all_answers_items];
      });
    },
    DeleteOption(questionIndex, sectionIndex, optionIndex) {
      if (
        this.examSections[sectionIndex]["questions"][questionIndex]["answer"] ==
        this.examSections[sectionIndex]["questions"][questionIndex]["options"][
          optionIndex
        ]["value"]
      ) {
        this.examSections[sectionIndex]["questions"][questionIndex]["answer"] =
          "";
      }
      this.examSections[sectionIndex]["questions"][questionIndex][
        "options"
      ].splice(optionIndex, 1);
    },
    AddOption(questionIndex, sectionIndex) {
      this.examSections[sectionIndex]["questions"][questionIndex][
        "options"
      ].push({ key: "Option", value: "" });
    },
    AddSentence(questionIndex, sectionIndex) {
      console.log(questionIndex);
      let joinLenght =
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"]
          .length;

      this.examSections[sectionIndex]["questions"][questionIndex]["joins"].push(
        {
          join_question: "",
          join_question_number:
            joinLenght > 0
              ? parseFloat(
                  this.examSections[sectionIndex]["questions"][questionIndex][
                    "joins"
                  ][
                    this.examSections[sectionIndex]["questions"][questionIndex][
                      "joins"
                    ].length - 1
                  ]["join_question_number"]
                ) + 1
              : 1,
          join_answer: "",
          join_answer_number:
            joinLenght > 0
              ? this.alphapaticChars[
                  this.alphapaticChars.indexOf(
                    this.examSections[sectionIndex]["questions"][questionIndex][
                      "joins"
                    ][
                      this.examSections[sectionIndex]["questions"][
                        questionIndex
                      ]["joins"].length - 1
                    ]["join_answer_number"]
                  ) + 1
                ]
              : this.alphapaticChars[0],
          all_answers_items: ["", "a"],
          join_mark: "",
          correct_answer: "",
        }
      );

      let all_answers_items = [];
      all_answers_items.push("");
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        all_answers_items.push(join.join_answer_number);
        // join.all_answers_items.push(new_char);
      });
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        join.all_answers_items = [...all_answers_items];
      });

      let correct_answers_items = [];
      this.examSections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_items.push(join.correct_answer);
      });

      correct_answers_items.forEach((correct) => {
        this.examSections[sectionIndex]["questions"][questionIndex][
          "joins"
        ].forEach((join) => {
          if (join.correct_answer != correct && correct != "") {
            if (join.all_answers_items.includes(correct)) {
              join.all_answers_items.splice(
                join.all_answers_items.indexOf(correct),
                1
              );
            }
          }
        });
      });
    },
    checkTypeQuestion(questionIndex, sectionIndex, questionType) {
      if (questionType == "tof") {
        this.examSections[sectionIndex]["questions"][questionIndex]["options"] =
          [
            { key: "Option1", value: "True" },
            { key: "Option2", value: "False" },
          ];
      } else if (questionType == "mcq") {
        this.examSections[sectionIndex]["questions"][questionIndex]["options"] =
          [{ key: "Option", value: "" }];
      } else if (questionType == "join") {
        this.examSections[sectionIndex]["questions"][questionIndex]["joins"] = [
          {
            join_question: "",
            join_question_number: 1,
            join_answer: "",
            join_answer_number: "a",
            all_answers_items: ["", "a"],
            join_mark: "",
            correct_answer: "",
          },
        ];
      } else {
        this.examSections[sectionIndex]["questions"][questionIndex]["options"] =
          [];
      }
    },
    checkAutoCorrection() {
      if (this.ExamData.auto_correction == true) {
        this.types = this.types.filter((item) => {
          return item.text != "Essay";
        });

        this.examSections[0].questions[0].type = "mcq";
        this.examSections[0].questions[0].options = [
          {
            key: this.$i18n.t("Option"),
            value: "",
          },
        ];
      }
    },
    getExamData() {
      axios
        .get(this.getApiUrl + "/exams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data;

          this.examSaved = this.ExamData.saved;
          this.confirmed = this.ExamData.confirmed;
          // this.admission = this.ExamData.admission;
          this.checkAutoCorrection();
          if (this.ExamData.admission) {
            this.types.splice(0, 1);
            this.examSections[0].questions[0].type = "join";
          }
          this.loaded = true;
          if (this.examSaved == true) {
            this.getEditOnlineExam();
          }
        });
    },
    addSection() {
      let sectionObj = {
        section_title: "",
        questions: [
          {
            head: "",
            type: "essay",
            Marks: "",
            min: "",
            max: "",
            tags: [],
            tag: "",
            question: "",
            answer: "",
            options: [],
            joins: [],
          },
        ],
      };
      this.examSections.push(sectionObj);
    },
    addQuestion(sectionIndex) {
      let type = "";
      // console.log(this.examSections[sectionIndex].questions.length - 1);
      if (
        sectionIndex >= 0 &&
        this.examSections[sectionIndex].questions.length - 1 != -1
      ) {
        this.selectedType =
          this.examSections[sectionIndex].questions[
            this.examSections[sectionIndex].questions.length - 1
          ].type;
        type = this.selectedType;
      } else {
        type = "essay";
      }
      let options;
      let joins;
      if (type == "tof") {
        options = [
          { key: "Option1", value: "True" },
          { key: "Option2", value: "False" },
        ];
      } else if (type == "mcq") {
        options = [{ key: "Option", value: "" }];
      } else if (type == "join") {
        joins = [
          {
            join_question: "",
            join_question_number: 1,
            join_answer: "",
            join_answer_number: "a",
            all_answers_items: ["", "a"],
            correct_answer: "",
          },
        ];
      } else {
        options = [];
      }
      let questionObj = {
        head: "",
        type: type,
        Marks: "",
        min: "",
        max: "",
        tags: [],
        tag: "",
        question: "",
        answer: "",
        options: options,
        joins: joins,
      };
      this.examSections[sectionIndex].questions.push(questionObj);
    },

    duplicateSection(section) {
      // console.log(section);
      let sections = [...this.examSections];
      let newSection = section;
      let questions = JSON.parse(JSON.stringify(newSection.questions));

      questions.forEach((q) => {
        q.question_id = "";

        if (q.joins && q.joins.length > 0) {
          q.joins.forEach((j) => {
            j.id = "";
          });
        }
      });
      let sec = {
        section_title: newSection.section_title,
        section_id: "",
        questions: questions,
      };
      sec.questions.question_id = "";
      sections.push(sec);

      this.examSections = [...sections];
    },
    duplicateQuestion(questionIndex, sectionIndex) {
      // let questionsArray = this.examSections[sectionIndex].questions;
      let newQuestion = Object.assign(
        {},
        this.examSections[sectionIndex].questions[questionIndex]
      );

      // newQuestion.joins.forEach(j => {
      //   j.id = "";
      // });
      let joins = JSON.parse(JSON.stringify(newQuestion.joins));
      joins.forEach((j) => {
        j.id = "";
      });
      // console.log(joins);
      let questionObj = {
        question_id: "",
        head: newQuestion.head,
        type: newQuestion.type,
        Marks: newQuestion.Marks,
        min: newQuestion.min,
        max: newQuestion.max,
        tags: newQuestion.tags,
        tag: newQuestion.tag,
        question: newQuestion.question,
        answer: newQuestion.answer,
        options: JSON.parse(JSON.stringify(newQuestion.options)),
        joins: JSON.parse(JSON.stringify(joins)),
      };

      this.examSections[sectionIndex].questions.push(questionObj);

      // this.examSections[sectionIndex].questions = [...questionsArray];
    },

    deleteSection(sectionIndex) {
      let sectionId = this.examSections[sectionIndex].section_id;
      if (sectionId) {
        axios
          .get(this.getApiUrl + "/onlineexams/deleteSection/" + sectionId, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.examSections.splice(sectionIndex, 1);
            }
          });
      } else {
        this.examSections.splice(sectionIndex, 1);
      }
    },
    deleteQuestion(index, sectionIndex) {
      let question_id =
        this.examSections[sectionIndex].questions[index].question_id;

      if (question_id) {
        axios
          .get(this.getApiUrl + "/onlineexams/deleteQuestion/" + question_id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.examSections[sectionIndex].questions.splice(index, 1);
            }
          });
      } else {
        this.examSections[sectionIndex].questions.splice(index, 1);
      }
    },
    saveExam() {
      /* validate msq questions */
      let msqValid = true;
      this.examSections.forEach((section) => {
        section.questions.forEach((question, indes) => {
          if (question.type == "mcq" && question.options.length > 0) {
            question.options.forEach((option) => {
              if (option.value == "") {
                msqValid = false;
              }
            });
          }
        });
      });
      /* end validate msq questions */

      if (msqValid == false) {
        console.log("this field is requered");
      } else {
        if (this.valid == false) {
          this.$refs.form.validate();
        } else {
          let error = false;
          if (this.examSections.length > 0) {
            this.examSections.forEach((element) => {
              element["questions"].forEach((question) => {
                if (
                  (question.answer == "" || question.answer == null) &&
                  question.type != "join"
                ) {
                  // console.log(question.type);
                  this.snack = true;
                  this.snackText = this.$i18n.t("please put all answers first");
                  this.snackColor = "red";
                  error = true;
                }
              });
            });
          }
          if (error == false && this.loading == false) {
            this.loading = true;
            axios
              .post(
                this.getApiUrl + "/onlineexams/store/" + this.examId,
                { sections: this.examSections },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                if (response.data.status.error == false) {
                  this.$router.push({
                    path: "/viewOnlineExam/" + this.examId,
                  });
                }
                this.loading = false;
              });
          }
        }
      }
    },
    updateExam() {
      /* validate msq questions */
      let msqValid = true;
      this.examSections.forEach((section) => {
        section.questions.forEach((question, indes) => {
          if (question.type == "mcq" && question.options.length > 0) {
            question.options.forEach((option) => {
              if (option.value == "") {
                msqValid = false;
              }
            });
          }
        });
      });
      /* end validate msq questions */

      if (msqValid == false) {
        console.log("this field is requered");
      } else {
        if (this.valid == false) {
          this.$refs.form.validate();
        } else {
          let error = false;
          if (this.examSections.length > 0) {
            this.examSections.forEach((element) => {
              element["questions"].forEach((question) => {
                if (
                  (question.answer == "" || question.answer == null) &&
                  question.type != "join"
                ) {
                  this.snack = true;
                  this.snackText = this.$i18n.t("please put all answers first");
                  this.snackColor = "red";
                  error = true;
                }
              });
            });
          }
          if (error == false && this.loading == false) {
            this.loading = true;
            axios
              .post(
                this.getApiUrl + "/onlineexams/edit/" + this.examId,
                { sections: this.examSections },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                // console.log(response.data.status.error);
                if (response.data.status.error == false) {
                  this.$router.push({
                    path: "/viewOnlineExam/" + this.examId,
                  });
                  this.loading = false;
                }
              });
          }
        }
      }
    },
    getEditOnlineExam() {
      axios
        .get(this.getApiUrl + "/onlineexams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.examSections = response.data.data["sections"];
        });
    },

    confirmExam() {
      axios
        .get(this.getApiUrl + "/onlineexams/confirmed/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.$router.push({
              path: "/viewOnlineExam/" + this.examId,
            });
          }
        });
    },

    openConfirmDialog(item, index, sectionIndex) {
      this.confirmDialog = true;
      this.deletedItem = item;
      this.selectedQuestionIndex = index;
      this.selectedSectionIndex = sectionIndex;
    },
    deleteItem(item) {
      if (item == "question" && confirm) {
        this.deleteQuestion(
          this.selectedQuestionIndex,
          this.selectedSectionIndex
        );
      } else if (item == "section" && confirm) {
        this.deleteSection(this.selectedSectionIndex);
      }
      this.confirmDialog = false;
    },
  },
  computed: {
    filteredItems() {
      // console.log(this.tag);
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  mounted() {
    this.classId = this.$router.currentRoute.params.classId;
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
    this.getTags();
    this.tab = this.$router.currentRoute.query.tab;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.exam-section {
  margin-bottom: 1rem;
}

.sectionInput {
  background-color: #eff3ff;
  padding: 25px 0px 25px 50px;
}
hr.hr-bold {
  border-top: 2px solid rgb(18 2 2 / 10%);
}

label {
  margin-top: 0 !important;
}
.clickable {
  cursor: pointer;
}
.questionbody {
  box-shadow: $box-shadow;
  padding-bottom: 2px !important;
  // margin-top: 2px;
  margin-bottom: 2px;
}
.addQuestion {
  padding: 10px;
  background-color: #eff3ff;
}

.addOption {
  padding: 5px;
  background-color: #eff3ff;
}
.divider {
  height: 2rem;
  width: 100%;
  background-color: #eff3ff;
  margin: 0 !important;
  padding: 0 !important;
}

.displayDiv {
  display: block !important;
}
.hiddenDiv {
  display: none;
}
.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}

.addSentenceSpan {
  margin-top: 0.5rem;
  cursor: pointer;
}
</style>
